import { Pipe, PipeTransform, inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterSelected } from '@ggp/generic/shared/services';
import { LocalizedDatePipe } from './localizedDate.pipe';
import { SliderType } from '@ggp/generic/shared/util/models';
import { Language } from '@ggp/ebp/leads/util';

@Pipe({
  name: 'dateRange',
  standalone: true,
})
@Injectable({ providedIn: 'root' })
export class DateRangePipe implements PipeTransform {
  readonly #translateService = inject(TranslateService);
  readonly #localizedDatePipe = inject(LocalizedDatePipe);

  transform(filter: FilterSelected): string {
    if (!filter?.displayText || !filter?.value?.minValue || !filter?.value?.maxValue) return '';

    const minValue = new Date(filter.value.minValue);
    const maxValue = new Date(filter.value.maxValue);
    let displayText = '';

    if (filter.value.dateType === SliderType.RANGE) {
      displayText = this.#getTextByLanguage(filter, minValue, maxValue);
    } else {
      const minDate = this.#localizedDatePipe.transform(minValue);
      const maxDate = this.#localizedDatePipe.transform(maxValue);
      displayText = this.#translateService.instant(filter.displayText, { minDate, maxDate });
    }

    const typeText = filter.value.rangeType ? `(${this.#translateService.instant('DELIVERY.' + filter.value.rangeType)})` : '';
    return `${displayText} ${typeText}`;
  }

  // eslint-disable-next-line max-lines-per-function
  #getTextByLanguage(filter: FilterSelected, minValue: Date, maxValue: Date) {
    const language = localStorage.getItem('currentLang') as Language;
    const filterName = this.#translateService.instant(filter.displayText + '_NAME');
    const fromTranslationText = this.#translateService.instant('DATE_RANGE.FROM');
    const start = this.#getValue(minValue);
    const startText = this.#getDayName(minValue);
    const toTranslationText = this.#translateService.instant('DATE_RANGE.TO');
    const end = this.#getValue(maxValue);
    const endText = this.#getDayName(maxValue);

    const todayText = this.#translateService.instant('DATE_RANGE.TODAY');
    const inTheFutureText = this.#translateService.instant('DATE_RANGE.IN_THE_FUTURE');
    const isStartInTheFuture = startText === inTheFutureText;
    const isEndInTheFuture = endText === inTheFutureText;

    if (language === Language.fr) {
      return `${filterName}: ${fromTranslationText} ${
        start.value === '' ? todayText : isStartInTheFuture ? `${start.value} ${start.range} ${startText}` : `${start.value} ${startText} ${start.range}`
      } ${toTranslationText} ${
        end.value === '' ? todayText : isEndInTheFuture ? `${end.value} ${end.range} ${endText}` : `${end.value} ${endText} ${end.range}`
      }`;
    } else if (language === Language.nl) {
      return `${filterName}: ${fromTranslationText} ${
        start.value === '' ? todayText : isStartInTheFuture ? `over ${start.value} ${start.range}` : `${start.value} ${start.range} ${startText}`
      } ${toTranslationText} ${end.value === '' ? todayText : isEndInTheFuture ? `over ${end.value} ${end.range}` : `${end.value} ${end.range} ${endText}`}`;
    } else if (language === Language.de) {
      return `${filterName}: ${fromTranslationText} ${
        start.value === '' ? todayText : isStartInTheFuture ? `in ${start.value} ${start.range}` : `vor ${start.value} ${start.range}`
      } ${toTranslationText} ${end.value === '' ? todayText : isEndInTheFuture ? `in ${end.value} ${end.range}` : `vor ${end.value} ${end.range}`}`;
    } else {
      return `${filterName}: ${fromTranslationText} ${start.value} ${start.range} ${startText} ${toTranslationText} ${end.value} ${end.range} ${endText}`;
    }
  }

  #getDayName(date: Date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);

    if (today.getTime() === date.getTime()) {
      return this.#translateService.instant('DATE_RANGE.TODAY');
    }

    return this.#translateService.instant(date < new Date() ? 'DATE_RANGE.AGO' : 'DATE_RANGE.IN_THE_FUTURE');
  }

  #getValue(targetDate: Date): { value: string; range: string } {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const normalizedTargetDate = new Date(targetDate);
    normalizedTargetDate.setHours(0, 0, 0, 0);

    const dayDifferenceFromToday = Math.floor((normalizedTargetDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    const numberOfYear = Math.floor(Math.abs(dayDifferenceFromToday) / 365);
    const numberOfMonths = this.#getNumberOfMonths(today, normalizedTargetDate);

    if (numberOfYear > 0) {
      return {
        value: numberOfYear.toString(),
        range: this.#translateService.instant(numberOfYear > 1 ? 'DATE_RANGE.YEARS' : 'DATE_RANGE.YEAR'),
      };
    } else if (numberOfMonths > 0) {
      return {
        value: Math.abs(numberOfMonths).toString(),
        range: this.#translateService.instant(Math.abs(numberOfMonths) > 1 ? 'DATE_RANGE.MONTHS' : 'DATE_RANGE.MONTH'),
      };
    } else {
      return {
        value: dayDifferenceFromToday === 0 ? '' : Math.abs(dayDifferenceFromToday).toString(),
        range: dayDifferenceFromToday === 0 ? '' : this.#translateService.instant(Math.abs(dayDifferenceFromToday) > 1 ? 'DATE_RANGE.DAYS' : 'DATE_RANGE.DAY'),
      };
    }
  }

  #getNumberOfMonths(startDate: Date, endDate: Date) {
    const start = startDate.getTime() > endDate.getTime() ? endDate : startDate;
    const end = startDate.getTime() > endDate.getTime() ? startDate : endDate;
    const months = (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth();

    if (end.getDate() < start.getDate()) {
      return months - 1;
    }

    return months;
  }
}
